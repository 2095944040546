<template>
  <Component :is="props.tag">
    <slot v-bind="{ hasTouch }" />
  </Component>
</template>

<script lang="ts" setup>
interface TouchSensorProps {
  tag?: string
}

const props = withDefaults(defineProps<TouchSensorProps>(), { tag: 'div' })
const { hasTouch } = useTouch()
</script>
