import { deviceType } from 'detect-it'

export const useTouch = () => {
  const hasTouch = ref(true)
  const dT = ref('')

  onMounted(() => {
    useResizeObserver(document.body, () => {
      dT.value = deviceType
      hasTouch.value = deviceType !== 'mouseOnly'
    })
  })

  return { hasTouch, deviceType: dT }
}
