import type { ProductFragment } from '@/shopify-storefront'

export const useShopifyProduct = (product: ProductFragment) => {
  const price = reactive({
    min: parseFloat(product.priceRange.minVariantPrice.amount),
    max: parseFloat(product.priceRange.maxVariantPrice.amount),
    compare: parseFloat(product.compareAtPriceRange.maxVariantPrice.amount),
    currency: product.priceRange.minVariantPrice.currencyCode,
  })

  const hasComparePrice = computed(() => price.compare > 0)
  const hasPriceRange = computed(() => price.max > price.min)
  const hasVariants = computed(() => product.variants.nodes.length > 1)
  const isSampler = computed(() => product.isSampler?.value === 'true')
  const isStarterKit = computed(() => product.isStarterKit?.value === 'true')
  const totalLitres = computed(() => product.totalLitres?.value)

  const hasNewSellingPlanStructure = computed(
    () => product.sellingPlanGroups.nodes.length > 1,
  )

  const sellingPlans = computed(() =>
    hasNewSellingPlanStructure.value
      ? product.sellingPlanGroups.nodes
          .map(({ sellingPlans }) => sellingPlans.nodes[0])
          .filter((plan) => !!plan)
          .sort(
            (a, b) =>
              parseInt(a.options[0].value || '0', 10) -
              parseInt(b.options[0].value || '0', 10),
          )
      : product.sellingPlanGroups.nodes[0]?.sellingPlans.nodes || [],
  )

  const hasSellingPlans = computed(() => sellingPlans.value.length)

  return {
    ...product,
    price,
    hasComparePrice,
    hasPriceRange,
    hasVariants,
    isSampler,
    isStarterKit,
    totalLitres,
    sellingPlans,
    hasSellingPlans,
    hasNewSellingPlanStructure,
  }
}
