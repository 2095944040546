<template>
  <NuxtLink
    :to="`/products/${handle}`"
    class="cloud-mask-lg group relative block aspect-square bg-cream-dark"
  >
    <TouchSensor
      v-if="images.length"
      v-slot="{ hasTouch }"
      class="relative h-full w-full"
    >
      <div
        v-for="(image, index) in images.slice(0, hasTouch ? 1 : 2)"
        :key="index"
        :class="index === 1 ? 'opacity-0 group-hover:opacity-100' : ''"
        class="absolute inset-0 transform-gpu transition-opacity duration-200"
      >
        <ShopifyImage
          v-if="image"
          :image="image"
          :sizes="sizes"
          lazy
          class="h-full w-full"
          inner-class="w-full"
        />
      </div>
    </TouchSensor>
    <div
      :class="small ? 'pt-10' : 'pt-9 md:pt-14 lg:pt-9 2xl:pt-14'"
      class="absolute inset-0 bottom-auto z-[2] flex flex-col items-center space-y-1 md:space-y-0"
    >
      <h2
        :class="small ? 'xl:text-[18px]' : ''"
        class="text-[18px] leading-[24px] md:text-[20px]"
        v-text="title"
      />
      <span
        v-if="!small"
        class="select-none text-[14px]/[16px] text-black opacity-50 md:text-[18px]/[24px]"
      >
        <span v-if="hasPriceRange">From </span>
        <template v-if="hasComparePrice && !hasPriceRange">
          <span class="line-through opacity-50">
            {{ $helpers.formatPrice(price.compare) }}
          </span>
          <span>&nbsp;</span>
        </template>
        <span v-text="$helpers.formatPrice(price.min)" />
        <span v-if="totalLitres">&nbsp;• Makes {{ totalLitres }}L</span>
      </span>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import type { ProductFragment } from '@/shopify-storefront'
import type { SrcsetSizes } from '@/components/payload/image/types'

interface ProductCardProps {
  product: ProductFragment
  sizes?: SrcsetSizes
  small?: boolean
}

const props = withDefaults(defineProps<ProductCardProps>(), {
  sizes: () => ({
    default: '90vw',
    md: '62vw',
    lg: '30vw'
  }),
  small: false,
})

const { handle, title, price, hasPriceRange, hasComparePrice, totalLitres } =
  useShopifyProduct(props.product)

const { images } = useShopifyVariantSecondaryImages(
  ref(props.product.variants.nodes[0]),
)
</script>
