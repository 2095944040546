import type {
  GetMediaImageQuery,
  ProductVariantFragment,
} from '@/shopify-storefront'

import { GetMediaImageDocument } from '@/graphql/shopify/storefront'

interface Image {
  id?: string | null
  url: string
}

interface Cache {
  [key: string]: Image[]
}

export const useShopifyVariantSecondaryImages = (
  variant: Ref<ProductVariantFragment>,
) => {
  const cachedImages: Cache = reactive({
    [variant.value.id]: [variant.value.image!],
  })

  const images = computed(() => {
    return cachedImages[variant.value.id] || []
  })

  const parsed = computed(() =>
    JSON.parse(variant.value.secondaryImages?.value || '[]'),
  )

  function load() {
    parsed.value.forEach((id: string, index: number) => {
      const { onResult, refetch } = useQuery<GetMediaImageQuery>(
        GetMediaImageDocument,
        { id },
        { clientId: 'shopify' },
      )

      onResult(({ data }) => {
        if (data?.node?.__typename === 'MediaImage') {
          cachedImages[variant.value.id][index + 1] = data.node.image!
        }
      })

      refetch()
    })
  }

  onMounted(() => {
    if (import.meta.client) {
      load()
    }
  })

  watch(variant, (newVariant) => {
    if (!cachedImages[newVariant.id]) {
      cachedImages[newVariant.id] = [newVariant.image!]

      load()
    }
  })

  return { images, totalLength: parsed.value.length + 1 }
}
